var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "home" }, [
    _c("h1", { staticClass: "heading" }, [_vm._v("Navigation buttons")]),
    _c("p", { staticClass: "home__buttons" }, [
      _c(
        "button",
        {
          staticClass: "button button--02",
          on: {
            click: function($event) {
              return _vm.$router.go(-1)
            }
          }
        },
        [_vm._v(" Go to back one page ")]
      ),
      _c(
        "button",
        {
          staticClass: "button button--02",
          on: {
            click: function($event) {
              return _vm.$router.push({ name: "BuyCardsPage" })
            }
          }
        },
        [_vm._v(" Go to Buy Cards demo page ")]
      ),
      _c(
        "button",
        {
          staticClass: "button button--02",
          on: {
            click: function($event) {
              return _vm.$router.push("/404")
            }
          }
        },
        [_vm._v(" Trigger 404 error ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }