import { render, staticRenderFns } from "./NavButtonsPage.vue?vue&type=template&id=3aa0aac7&"
var script = {}
import style0 from "./NavButtonsPage.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3aa0aac7')) {
      api.createRecord('3aa0aac7', component.options)
    } else {
      api.reload('3aa0aac7', component.options)
    }
    module.hot.accept("./NavButtonsPage.vue?vue&type=template&id=3aa0aac7&", function () {
      api.rerender('3aa0aac7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/NavButtonsPage.vue"
export default component.exports